import { observable } from 'mobx'
import React from 'react'
import { currencyFormat, currencySymbol, penceToPounds } from '../currency'

const yEdgeCases = new Set(['day'])
export const defaultCurrency = 'gbp' as SupportedCurrency
export const defaultCountry = 'GB' as SupportedCountry

export const SMS_COST = 7
export const EMAIL_COST = 0

class I18n {
  @observable language = ''
  languages: Record<string, Record<string, string>> = {}
  @observable currency = defaultCurrency
  @observable country = defaultCountry
  @observable costSettings: Record<string, Record<string, number>> = {}
  locale = 'en-GB'

  constructor(languages: Record<string, Record<string, string>>, language: string) {
    this.languages = languages
    this.language = language
  }

  setLanguage(language: string) {
    this.language = language
  }

  get currentLanguage() {
    return this.languages[this.language]
  }

  setCurrency(currency: SupportedCurrency) {
    this.currency = currency
  }

  setCountry(country: SupportedCountry) {
    this.country = country
  }

  setCostSettings(costSettings: Record<string, any>) {
    this.costSettings = costSettings
  }

  setLocale(locale: string) {
    this.locale = locale
  }

  // Method to translate string
  s = (string: string, ...args: (string | number | null | undefined)[]) => {
    if (string === '£') return this.currencySymbol

    // Create lowercase version
    const stringL = string.toLowerCase()

    // Match on string, lowercased string or return initial string passed in
    let str = this.currentLanguage![string] || this.currentLanguage![stringL] || string

    // Do templating
    args.forEach((arg, index) => {
      str = str.replace(new RegExp(`\\$${index}`, 'g'), `${arg}`)
    })

    return str
  }

  pluralise = (string: string, count = 0) => {
    const i18nString = this.s(string)

    if (count === 1) return i18nString

    // For english
    const lastLetter = i18nString.charAt(i18nString.length - 1)
    if (lastLetter === 'y') {
      // y edge cases
      if (yEdgeCases.has(i18nString.toLocaleLowerCase())) {
        return i18nString + 's'
      }

      return i18nString.slice(0, i18nString.length - 1) + 'ies'
    }
    if (lastLetter === 's') return i18nString + 'es'

    return i18nString + 's'
  }

  intToOrdinal = (num: number): string => {
    num = Math.round(num)
    const numString = num.toString()

    if (this.locale === 'en-GB') {
      // If the ten's place is 1, the suffix is always "th"
      // (10th, 11th, 12th, 13th, 14th, 111th, 112th, etc.)
      if (Math.floor(num / 10) % 10 === 1) {
        return numString + 'th'
      }

      // Otherwise, the suffix depends on the one's place as follows
      // (1st, 2nd, 3rd, 4th, 21st, 22nd, etc.)
      switch (num % 10) {
        case 1: return numString + 'st'
        case 2: return numString + 'nd'
        case 3: return numString + 'rd'
        default: return numString + 'th'
      }
    }

    return numString
  }

  currencyFormat = (amount: number, options?: CurrencyOptions) => {
    const { fractionDigits = 2, isPennies = true } = options || {}
    return currencyFormat(amount, fractionDigits, isPennies, this.locale, this.currency)
  }

  formatCurrencyForStats = (amount: number) => {
    const pounds = penceToPounds(amount)
    const symbol = this.currencySymbol

    if (pounds >= 1000) {
      if ((pounds / 1000) % 1 === 0) return symbol + `${pounds / 1000}k`

      return symbol + `${(pounds / 1000).toFixed(1)}k`
    }

    return symbol + `${pounds.toFixed(0)}`
  }

  get currencySymbol() {
    return currencySymbol(this.locale, this.currency)
  }

  formattedCost = (feature: 'sms' | 'email') => {
    if (this.costSettings && this.costSettings[feature]) {
      const cost = this.costSettings[feature]![this.country] || this.costSettings[feature]!['default']
      if (cost) return currencyFormat(cost)
    }

    if (feature === 'sms') {
      return this.currencyFormat(SMS_COST)
    }

    return this.currencyFormat(EMAIL_COST)
  }
}

type CurrencyOptions = { fractionDigits?: number, isPennies?: boolean }
export type $i18n = I18n
export default I18n

export const I18nContext = React.createContext({} as I18n)

export const useI18n = () => {
  const i18n = React.useContext(I18nContext)
  if (!i18n) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('i18n must be used within a I18nContext.')
  }
  return i18n
}